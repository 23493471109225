<template>
  <div
    class="app app-auth-forgot-password align-content-stretch d-flex flex-wrap justify-content-end"
  >
    <div class="app-auth-background hidden-on-mobile">&nbsp;</div>
    <div class="app-auth-container">
      <div class="logo">
        <a href="https://www.setxrm.com/" target="_blank">&nbsp;</a>
      </div>
      <p class="auth-description">
        {{
          $t(
            "ForgotPassword.AuthDescription1",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}<br />{{
          $t(
            "ForgotPassword.AuthDescription2",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
        <router-link :to="{ path: '/sign-in' }">{{
          $t("Buttons.Login", {}, { locale: this.$store.state.activeLang })
        }}</router-link>
      </p>
      <form
        class="form w-100 needs-validation"
        id="forgotPassword"
        @submit="forgotPassword"
        novalidate
      >
        <div class="auth-credentials mb-3">
          <label for="email" class="form-label required">{{
            $t(
              "ForgotPassword.Email",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <div class="position-relative">
            <input
              data-autofocus="true"
              type="email"
              class="form-control"
              @keyup.enter="forgotPassword"
              id="email"
              v-model="userData.email"
              required
            />
            <div class="invalid-tooltip">
              {{
                this.$t(
                  "FieldErrors.EmailNotValid",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
          </div>
        </div>
        <div class="auth-submit">
          <button
            v-if="!formPosting"
            type="button"
            @click="forgotPassword"
            :disabled="formPosting"
            class="btn btn-primary"
          >
            {{
              $t(
                "Buttons.ForgotPassword",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <div class="spinner-border text-primary" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </form>
      <div class="divider"></div>
      <div class="d-flex justify-content-between align-items-center">
        <MembershipLocaleSwitcher />
        <SocialIcons />
      </div>
    </div>
  </div>
</template>
<script>
import SocialIcons from "@/components/layout/SocialIcons";
import MembershipLocaleSwitcher from "@/components/custom/locale-switchers/MembershipLocaleSwitcher.vue";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import $ from "jquery";

export default {
  components: {
    SocialIcons,
    MembershipLocaleSwitcher,
  },
  data() {
    return {
      userData: {
        email: null,
        clientUrl: `${window.location.origin}/#/`,
      },
      formPosting: false,
    };
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }
  },
  methods: {
    getResponseMessage(response) {
      var emailAddress = this.userData.email;
      this.userData.email = "";
      if (response.isOk) {
        // return this.$t(
        //   "ForgotPassword.SuccessMessage",
        //   {},
        //   { locale: this.$store.state.activeLang }
        // ).replaceAll("EMAIL_ADDRESS", emailAddress);
      }
      // return response.message;

      return this.$t(
        "ForgotPassword.SuccessMessage",
        {},
        { locale: this.$store.state.activeLang }
      ).replaceAll("EMAIL_ADDRESS", emailAddress);
    },
    forgotPassword(event) {
      var form = $("form#forgotPassword"),
        self = this;
      form.addClass("was-validated");
      event.stopPropagation();
      event.preventDefault();

      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.email) ||
        this.formPosting
      ) {
        return;
      }

      this.formPosting = true;
      this.$authAxios
        .post("/PasswordReset", {
          email: this.$root.encrypt(this.userData.email, true),
          clientUrl: this.userData.clientUrl,
        })
        .then((result) => {
          this.formPosting = false;
          var response = result.data;
          form.removeClass("was-validated");
          // if (response.isOk) {

          // }

          Swal.fire({
            width: "auto",
            html:
              "<span class='fw-bold fs-6'>" +
              self.getResponseMessage(response) +
              "</span>",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: this.$t(
              "Buttons.Close",
              {},
              { locale: this.$store.state.activeLang }
            ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
            },
          });
        });
    },
  },
  created() {
    this.$root.changeDocumentTitle(
      this.$t(
        "ForgotPassword.Title",
        {},
        { locale: this.$store.state.activeLang }
      )
    );
  },
};
</script>
